/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */
import { Clip } from 'phonograph';

import 'whatwg-fetch'

import {isMobile, isSafari, isAndroid } from 'react-device-detect';

import React from "react"


class Player extends React.Component {
	constructor(props){
		super(props)

		this.Started = false
		this.Ended = false
		this.Loaded = false
		this.playheadOffset = 0.0
		this.ConstantOffset = 0.0
		this.SyncdFrames = 0
		this.LastSet = 0
		this.UserOffset = 0.0
		this.clip = null
		
		if(!isMobile){
			this.ConstantOffset = 0
		}
		if(isSafari){
			this.ConstantOffset = -.1
		}
		if(isAndroid){
			this.ConstantOffset = 0
		}
	}

	LoadClip(clipPath, loadedCB){
		this.Loaded = false
		this.clip = new Clip({ url: clipPath });
		this.clip.buffer(true).then( () => {
		});
		this.clip.on('canplaythrough', () => {
			this.Loaded = true
			loadedCB()
		})
		this.clip.on('loaderror', err => {
			window.alert("failed to load clip: "+err)
		})
	}

	Start(preroll){
		this.clip.volume = 1
		this.clip.currentTime = preroll
		this.clip.play();
		this.Started = true
	}

	End(){
		this.clip.pause();
		this.clip.volume = 0
		this.Started = false
		this.Ended = true
		this.clip.currentTime = 0.0
		this.clip.dispose()
		this.Loaded = false
	}

	Bump(preroll){
			var diff = (this.clip.currentTime - preroll)-this.ConstantOffset
			var absDiff = Math.abs(diff)
			if(absDiff > 0.05){
				this.SyncdFrames = 0
				//If its in front of the correct timing...
				if(diff > 0){
					//nudge it back.)
					this.playheadOffset -= 0.01
				}
				//If its behind the correct timing...
				if(diff < 0) {
					//nudge it forward.
					this.playheadOffset += 0.01
				}
				this.clip.currentTime = preroll+this.playheadOffset
	
	
			} else {
				this.SyncdFrames += 1
				//if (this.SyncdFrames < 50){
				//	var v = Math.min(Math.max(this.SyncdFrames/50 , 0), 1);
				//	this.clip.volume = v
				//}
			}
		
	}

	render(){
		return(
			<div className="phonoPlayer">
			</div>
		)
	}
}

export default Player