/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */
import React from 'react'
import {Container, Grid, Button} from 'semantic-ui-react'
import { withRouter } from "react-router";

import './home.css'

import FullColorLogo from '../../resources/Logo_FullColor.png'

class Main extends React.Component{

	state = {}

	handleItemClick = (e, { name }) =>{
		this.setState({ activeItem: name })
		this.props.history.push('/'+name)
	} 


	render(){
		const { activeItem } = this.state
		return(
		<div className="Main">
			<Container>
			<Grid centered>
				<Grid.Row className="HeroImage">
					<Grid.Column mobile={12} computer={8}>
						<img src={FullColorLogo} alt="logo"></img>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Grid>
				<Grid.Row className="EventsFeed">
					<Grid.Column textAlign='center'>
						<Grid.Row className="Header">
							<h1>Upcoming Events</h1>
						</Grid.Row>
						<Grid.Row className="Event">
							<Button fluid name='kbridge' active={activeItem === 'kbridge'} onClick={this.handleItemClick}>Kosciuszko Bridge</Button>
						</Grid.Row>
						<Grid.Row className="Event">
							<Button fluid name='esb' active={activeItem === 'esb'} onClick={this.handleItemClick}>Empire State Building</Button>
						</Grid.Row>
						<Grid.Row className="Event">
							<Button fluid name='mc' active={activeItem === 'mc'} onClick={this.handleItemClick}>Metro Center Vine Wall</Button>
						</Grid.Row>
						<Grid.Row className="Event">
							<Button fluid name='vp' active={activeItem === 'vp'} onClick={this.handleItemClick}>Vulcan Park</Button>
						</Grid.Row>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Grid centered>
			</Grid>
		
			</Container>

		</div>
		)
	}
}

export default withRouter(Main)