

import {isMobile, isSafari, isAndroid } from 'react-device-detect';

import React from "react"

const {Howl, Howler} = require('howler');
Howler.autoUnlock = true
Howler.usingWebAudio = true

class Player extends React.Component {
	constructor(props){
		super(props)
		
		this.tempClip =  null
		this.Started = false
		this.Ended = false
		this.Loaded = false
		this.playheadOffset = 0.0
		this.ConstantOffset = 0.0
		this.SyncdFrames = 0
		this.LastSet = 0
		this.UserOffset = 0.0
		this.clip = null
		
		if(!isMobile){
			this.ConstantOffset = 0
		}
		if(isSafari){
			this.ConstantOffset = -.1
		}
		if(isAndroid){
			this.ConstantOffset = 0
		}
	}

	componentDidMount(){
		this.tempClip = new Howl({
			src: ['https://d3ffar08ccyx1n.cloudfront.net/silence.mp3'],
			autoplay: false,
			loop:false,
			preload:true
		})
	}

	PlayEmpty(){
		this.tempClip.play()
	}

	LoadClip(clipPath, loadedCB){

		this.clip = new Howl({
			src: [clipPath],
			autoplay: false,
			loop: false, 
			preload: true
		});

		this.clip.once('load', function(){
			this.Loaded = true
			loadedCB()
		})

		this.clip.once('loaderror', function(err){
			window.alert("failed to load clip: "+err)
		})

		this.clip.once('end', function(){

		})

		this.Loaded = false
	}

	Start(preroll){
		this.clip.seek(preroll)
		this.clip.play();
		this.Started = true
	}

	End(){
		this.clip.stop()
		this.clip.unload()
		this.Started = false
		this.Ended = true
		this.Loaded = false
	}

	Bump(preroll){
			var diff = (this.clip.seek() - preroll)-this.ConstantOffset
			var absDiff = Math.abs(diff)
			if(absDiff > 0.05){
				this.SyncdFrames = 0
				//If its in front of the correct timing...
				if(diff > 0){
					//nudge it back.)
					this.playheadOffset -= 0.01
				}
				//If its behind the correct timing...
				if(diff < 0) {
					//nudge it forward.
					this.playheadOffset += 0.01
				}
				this.clip.seek(preroll+this.playheadOffset)
	
	
			} else {
				this.SyncdFrames += 1
				//if (this.SyncdFrames < 50){
				//	var v = Math.min(Math.max(this.SyncdFrames/50 , 0), 1);
				//	this.clip.volume = v
				//}
			}
		
	}

	render(){
		return(
			<div className="howlPlayer">
			</div>
		)
	}
}

export default Player