import React from 'react';

class ExternalLayout extends React.Component{
	constructor(props){
		super(props)
		this.state = {}
	}

	render(){
		return (
			<>
				<main>
					{<this.props.children/>}
				</main>
			</>
		)
	}
}


export default ExternalLayout