import React from 'react';

import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom'

import './app.css';

import View from '../components/view/view'
import {SyncProvider} from '../components/syncComponent/syncComponent'

import Home from '../views/home/home'
import Connection from '../views/connection/connection'
import OldConnection from '../views/OLD_connection/connection'
import Simon from '../views/simon/simon'
import Previz from '../views/previz/previz'

class App extends React.Component {

	render(){
		return(
			<div className="App">
			<SyncProvider>
				<Router>
					<Route exact path="/"
						render={()=> <View display={Home} layout='external'
						title="Spark Sync" />}/>

					<Route path="/connect/:syncTag"
						render={(props)=> <View display={Connection} layout='app'
						title="Spark Sync" {...props} />}/>

					<Route path="/simon/:syncTag"
						render={(props)=> <View display={Simon} layout='app'
						title="Spark Sync" {...props} />}/>

					<Route path="/old/:syncTag"
						render={(props)=> <View display={OldConnection} layout='app'
						title="Spark Sync" {...props} />}/>

					<Route path="/previz/:syncTag"
						render={(props)=> <View display={Previz} layout='app'
						title="Spark Sync" {...props} />}/>

					<Route path="/kbridge" component={()=><Redirect to={'/connect/12345abcde'}/>} />
					<Route path="/esb" component={()=><Redirect to={'/connect/e0e9fdba7e6bd0aa1b11'}/>} />
					<Route path="/mc" component={()=><Redirect to={'/old/35062e536c20b5dccb86'}/>} />
					<Route path="/vp" component={()=><Redirect to={'/connect/a5cb3d4b0036bd57a584'}/>} />	
				</Router>
			</SyncProvider>
			</div>
		)
	}
}

export default App;