/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */
import React from "react"
import {isEdge, isIE } from 'react-device-detect';

import SyncAudioInterface from "../../components/OLD_syncAudioInterface/SyncAudioInterface"
import { Segment, Grid, Icon, Loader, Button, Container, Placeholder } from 'semantic-ui-react'


import {ViewContext} from '../../components/view/view'

import './connection.css'


var SyncRouterURL = "https://caesium-worker.tactman.workers.dev/systems/"  //TODO this needs to change to SparkSync domain.

class Connection extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			loading: true,
			activated: false,
			connected: true,
			synced: false,
			started: false,
			venueImage: null,
			showOver: false,
			venueLinks: []
		}
		this.SyncRoute = null
		this.SyncTag = null
	}

	componentDidMount(){
		this.SyncTag = this.context.viewProps.match.params.syncTag

		//Ask Sync Router where the sync core is.
		window.fetch(SyncRouterURL+this.SyncTag)
		.then((res)=>{
			//Convert response to JSON
			return res.json()})
		.then((doc)=>{
			//If we got a response...
			if (doc.success){
				this.SyncRoute = doc.publicInfo.link
				this.setState({venueImage: doc.publicInfo.logoPath, venueLinks: doc.publicInfo.links})
			}
		})

	}

	Links(links){
		var toDisplay = []
		if(links){
			links.forEach((linkObject, idx)=> {
				toDisplay.push(<a key={idx} target="_blank" rel="noopener noreferrer" href={linkObject.link}>{linkObject.text}</a>)
			});
		} else {
			toDisplay = <div></div>
		}
		return toDisplay
	}


	handleClick(){
		this.setState({activated:true})
		this.refs.SyncInterface.SyncOk()
		
	}

	handleSyncd(){
		this.setState({synced:true})
	}

	handleShowStarted(){
		this.setState({started:true})
	}

	handleNoCore(){
		this.setState({connected: false})
	}

	handleShowOver(){
		this.setState({showOver: true})
	}
	render(){

		return(
			
			<div>

			<Container>
			<Grid centered>
			
			<Grid.Row>
				<Grid.Column mobile={10} computer={8}>
					<div>
						{this.state.venueImage ? (
							<div><img className="ProjectImage" src={this.state.venueImage} alt="venue logo"></img></div>
							):(
							<div>
								<Placeholder>
									<Placeholder.Image></Placeholder.Image>
								</Placeholder>
							</div>)}
					</div>
				</Grid.Column>
			</Grid.Row>
			
			</Grid>

			<Segment className="InfoSegment" placeholder  textAlign='center'>
				
				{this.state.activated ? (
					this.state.started ?(<div><Icon name="headphones" size='huge'/><h3>Turn your volume up and enjoy the show.</h3></div>
						):(
							this.refs.SyncInterface.state.synced ?(
								<div><Icon name="hourglass outline" size='huge'/><h3>Please stay tuned. This event will begin shortly.</h3></div> 
							):(
								this.state.connected ?(
									<Loader active size='massive'>Syncing to show</Loader>
								):(
									<div><Icon name="exclamation" size='huge'></Icon><h3>Shows are scheduled everyday at 6pm, 7pm, 8pm, and 9pm!</h3></div>
								)
							)
						)
				):(
					isEdge || isIE ?(
						<div>
							<Icon name="exclamation" size='huge'></Icon>
							<h3>The synchonization features of Spark Sync are currently only supported in Chrome, Safari, and FireFox.</h3>
						</div>
					):(
						<Button className="SyncButton" onClick={this.handleClick.bind(this)} size="huge">Click here to listen to the show!</Button>
					)
				)}
				{ isEdge || isIE ? (<div></div>):(
					<SyncAudioInterface ref="SyncInterface" SyncRoute={this.SyncRoute} SyncdCallback={this.handleSyncd.bind(this)} ConnectionIssueCB={this.handleNoCore.bind(this)} ShowOverCB={this.handleShowOver.bind(this)} ShowStartedCB={this.handleShowStarted.bind(this)} ></SyncAudioInterface>
				)}
			</Segment>

			<Segment className="InfoSegment">
				{this.state.venueLinks.length >0 ? (
					<div>{this.Links(this.state.venueLinks)}</div>
				):(
					<Placeholder>
						<Placeholder.Line/>
						<Placeholder.Line/>
						<Placeholder.Line/>
					</Placeholder>
				)}
				
			</Segment>
			</Container>
			</div>
		)
	}
}

Connection.contextType = ViewContext

export default Connection;