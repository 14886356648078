/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */



var socket = null
var SyncAck = false
var AverageTimeDifferenceRange = []
var AverageRange = []
var AverageTimeDifference = 10000
var AverageDifferential = 10000


var rangeLength = 50
var AverageRangeLength = 10
var DeviationThreshold = 10

var PercentSynced = 0.0
var Playing = false
var StartedAt = 0

/** handles messages sent from the Sync Core service.
 *  @param {any} ev - message from Sync Core 
 */
function handleMessage(ev){
	
	//Convert message from SyncCore to JSON
	var data = JSON.parse(ev['data'])

	//If its valid
	if("MessageType" in data){

		//Process the server time.
		gotServerTime(data.ServerTime)

		//If we are done syncing and we have not told the SyncCore - send SyncAck.
		if(!SyncAck && PercentSynced === 1){
			socket.send(JSON.stringify({MessageType: "SyncAck", FullySynced: true, AverageError: AverageDifferential}))
			SyncAck = true
		}
		// See if the show has started..
		if(data.MessageType === "info"){
			Playing = data.Started 
			StartedAt = data.StartedAt
		}
		return data
	} 

}

/** Processes sync messages and find the average time offset.
 *  @param {number} serverTimestamp - Timestamp from Sync Core in Nanosecods.
 */
function gotServerTime(serverTimestamp){
	// Find difference between local clock and server clock in ms.
	var clientServerTimeDifference = (serverTimestamp/1000000) - performance.now();

	// Add the difference to a rolling array
	AverageTimeDifferenceRange.push( clientServerTimeDifference )
	if( AverageTimeDifferenceRange.length > rangeLength ){
		AverageTimeDifferenceRange.shift();
	}
	// As the rolling array fills, adjust percentage.
	PercentSynced = ((AverageTimeDifferenceRange.length / rangeLength)*.9);

	// Sum all of the differences.
	var sumOfTimeDifferences = ( AverageTimeDifferenceRange.reduce( (total, num)=> total+num) );

	if(AverageTimeDifferenceRange.length === rangeLength){
		//Find the average difference based on how many samples are present.
		AverageTimeDifference = sumOfTimeDifferences / AverageTimeDifferenceRange.length;
		//Add the Average Time Difference to another rolling range.
		AverageRange.push(AverageTimeDifference)
		if(AverageRange.length > AverageRangeLength){
			AverageRange.shift();
		}

		//Once we will the range...
		if(AverageRange.length === AverageRangeLength){
			// Find High/Low
			var peak = Math.max(...AverageRange)
			var trough = Math.min(...AverageRange)
			
			// Find Error
			AverageDifferential = peak - trough
			if (AverageDifferential < DeviationThreshold){
				PercentSynced = PercentSynced+.1
			}
		}
	}
}

/** Calculates the preroll that the audio player should be tracking too.
 *  @returns {number} Preroll time since show started. 
 */
function CalcPreRoll(){
	var prerollMS = performance.now() - ((StartedAt/1000000) - AverageTimeDifference)
	return (prerollMS/1000)
}

/** Opens socket connection to Sync Core service.
 *  @param {string} - the Sync Core public address with port and pathname (ws://0.0.0.0:8080/pathname)
 *  @param {function} - A callback for when the time offset error is negligable 
 *  @param {function} - A callback for if there is an error connecting. 
 */
function StartSync(addr, newInfoCB, SyncdCB, ErrorCB){
	//Create a new websocket connection with SyncCore
	socket = new WebSocket(addr)
	//If there is an error, Tell the viewer.
	socket.addEventListener("error", (err)=>{
		ErrorCB()
	})

	//Handle any messages we get from the SyncCore.
	socket.addEventListener("message", (ev)=>{
		var messageData = handleMessage(ev)
		// When the We have completed the sync process, tell viewer. 
		if(PercentSynced === 1.0){
			SyncdCB()
		}
		if(messageData.MessageType === "info"){
			newInfoCB(messageData)
			return
		}
		if(messageData.MessageType === "ping"){
			newInfoCB(messageData)
			return
		}
	})
}

/** Starts listening for start command from Sync Core
 * @param {function} - A callback for when we recieve a start message from Sync Core
 */
// There is a potential edge case here where we receive the Start command while setting this up. 
function WaitForStart(StartedCB, EndedCB, newInfoCB){
	if(Playing){
		console.log('we should be starting')
		StartedCB()
	}
	socket.addEventListener("message", (ev)=>{
		var messageData = handleMessage(ev)
		//console.log(messageData)
		if(messageData.MessageType === "info"){
			newInfoCB(messageData)
			return
		}
		if(messageData.MessageType === "ping"){
			newInfoCB(messageData)
			return
		}
		if(messageData.Started){
			console.log('we should be starting')
			StartedCB()
		} else {
			EndedCB()
		}
	})
}




module.exports = {StartSync, WaitForStart, CalcPreRoll}







