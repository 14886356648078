import React from 'react';

import {SyncContext} from '../syncComponent/syncComponent'
import {Button, Loader} from 'semantic-ui-react'


//Audio package.
const {Howl, Howler} = require('howler');
Howler.autoUnlock = true
Howler.usingWebAudio = true

class SyncStarter extends React.Component{
	constructor(props){
		super(props)
		this.state={
			activated: false,
			syncing: false,
			doneSyncing: false,
		}
		this.silenceTone = new Howl({
			src: ['https://d3ffar08ccyx1n.cloudfront.net/silence.mp3'],
			autoplay: false,
			loop: false, 
			preload: true
		})
	}
	doSync(){
		this.silenceTone.play()
		this.setState({activated: true, syncing:true})
		if(this.props.SyncStarted){
			this.props.SyncStarted()
		}
		this.context.SyncOk(this.props.SyncRoute, this.syncProcessCompleted.bind(this), this.gotNewInfo.bind(this), this.connectionIssue.bind(this))
	}

	syncProcessCompleted(){
		this.setState({syncing:false, doneSyncing: true})
		if(this.props.SyncdCB){
			this.props.SyncdCB()
		}
		this.context.WaitForStart(this.started.bind(this), this.ended.bind(this), this.update.bind(this), this.gotNewInfo.bind(this))
	}

	started(){
		if(this.props.StartShow){
			this.props.StartShow()
		}
	}

	ended(){
		if(this.props.EndShow){
			this.props.EndShow()
		}
	}

	update(preroll){
	}

	gotNewInfo(message){
		//This gets calledback with messages directly from the sync-core. 
		if(message.MessageType === "ping"){
			//These messages will contain number of active players.
			if(this.props.UsersUpdate){
				this.props.UsersUpdate(message.NumConnected)
			}
		}
	}

	connectionIssue(){
		this.setState({syncing: false, doneSyncing: false})
	}

	render(){
		return(
		<div>
			{this.state.activated ? (
				this.state.syncing ? (
					<Loader active size='massive'>Syncing to show</Loader>
				):(
					<div></div>
				)
			):(
				<Button fluid className="SyncButton" onClick={this.doSync.bind(this)} size="huge">{this.props.Label}</Button>
			)}
		</div>
		)
	}
}

SyncStarter.contextType = SyncContext

export default SyncStarter