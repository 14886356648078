
import React from "react"
import {isEdge, isIE } from 'react-device-detect';

import { Segment, Grid, Icon, Container, Placeholder } from 'semantic-ui-react'


import {ViewContext} from '../../components/view/view'
import SyncAudio from '../../components/syncAudio/syncAudio'
import SyncStarter from '../../components/syncStarter/syncStarter'

import './connection.css'

var SyncRouterURL = "https://caesium-worker.tactman.workers.dev/systems/"

class Connection extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			loading: true,
			activated: false,
			connected: true,
			synced: false,
			started: false,
			venueImage: null,
			showOver: false,
			venueLinks: []
		}
		this.SyncRoute = null
		this.SyncTag = null
		this.tempAudio = null
	}

	componentDidMount(){
		this.SyncTag = this.context.viewProps.match.params.syncTag

		//Ask Sync Router where the sync core is.
		window.fetch(SyncRouterURL+this.SyncTag)
		.then((res)=>{
			//Convert response to JSON
			return res.json()
		})
		.then((doc)=>{
			//If we got a response...
			if (doc.success){
				this.SyncRoute = doc.publicInfo.link
				this.setState({venueImage: doc.publicInfo.logoPath, venueLinks: doc.publicInfo.links})
			}
		})

	}

	Links(links){
		var toDisplay = []
		if(links){
			links.forEach((linkObject, idx)=> {
				toDisplay.push(<a key={idx} target="_blank" rel="noopener noreferrer" href={linkObject.link}>{linkObject.text}</a>)
			});
		} else {
			toDisplay = <div></div>
		}
		return toDisplay
	}


	handleClick(){
		this.setState({activated:true})
		this.AudioPlayer.playEmpty()
	}

	handleSyncd(){
		this.setState({synced:true})
	}

	handleShowStarted(){
		this.setState({started:true})
		console.log('show started')
	}

	handleNoCore(){
		this.setState({connected: false})
		console.log('no core')
	}

	handleShowOver(){
		this.setState({showOver: true})
		console.log('show over')
	}
	render(){

		return(
			
			<div>

			<Container>

			{/* Project Image */}
			<Grid centered>
				<Grid.Row>
					<Grid.Column mobile={10} computer={8}>
						<div>
							{this.state.venueImage ? (
								<div><img className="ProjectImage" src={this.state.venueImage} alt="venue logo"></img></div>
								):(
								<div>
									<Placeholder>
										<Placeholder.Image></Placeholder.Image>
									</Placeholder>
								</div>)}
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>


			{/* Information Segment */}
			<Segment className="InfoSegment" placeholder  textAlign='center'>
				{ isEdge || isIE ? (
					<div>
						<Icon name="exclamation" size='huge'></Icon>
						<h3>The synchonization features of Spark Sync are currently only supported in Chrome, Safari, and FireFox.</h3>
					</div>
				):(
					this.state.synced? (
						this.state.started? (
								<div>
									<Icon name="headphones" size='huge'/>
									<h3>Turn your volume up and enjoy the show.</h3>
								</div>
							):(
								<div>
									<Icon name="hourglass outline" size='huge'/>
									<h3>Please stay tuned. This event will begin shortly.</h3>
								</div> 
							)
						):(
						<div>
						</div>
					)
				)
			}
			<SyncStarter SyncRoute={this.SyncRoute} StartShow={this.handleShowStarted.bind(this)} EndShow={this.handleShowOver.bind(this)} SyncdCB={this.handleSyncd.bind(this)} Label="Click here to start syncing"></SyncStarter>
			</Segment>


			<SyncAudio Passive onRef={ref=>(this.AudioPlayer = ref)}></SyncAudio>


			<Segment className="InfoSegment">
				{this.state.venueLinks.length >0 ? (
					<div>{this.Links(this.state.venueLinks)}</div>
				):(
					<Placeholder>
						<Placeholder.Line/>
						<Placeholder.Line/>
						<Placeholder.Line/>
					</Placeholder>
				)}
				
			</Segment>
			</Container>
			</div>
		)
	}
}

Connection.contextType = ViewContext

export default Connection;