/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */
import React from 'react';
import {StartSync, WaitForStart, CalcPreRoll} from '../syncComponent/sync'
import { SyncContext } from "../syncComponent/syncComponent";
import PhonoPlayer from '../players/player'
import HowlPlayer from '../players/howlerPlayer'
import 'whatwg-fetch'

/** SyncAudioInterface which manages a shared state between sync module and player module. */
class SyncAudioInterface extends React.Component {
    constructor(){
        super()
        this.SyncClient = null
		this.Synced = false
		this.preroll = 0
		this.AudioIsLoaded = false
		this.RecalcInterval = null
		this.AudioClipPath = null
		this.state = {
			preroll: 0,
			offset: 0,
			started: false,
			startedat: 0,
			synced: false
		}
	}

    
	SyncOk(){ 
		//Get Sync Route from parent.
        this.Route = this.props.SyncRoute

		if(this.Route){
			StartSync(this.Route, this.GotNewAudio.bind(this), this.SetSyncStatus.bind(this), this.ConnectionIssue.bind(this))
		} else {
			this.ConnectionIssue()
		}
		this.refs.HowlComp.PlayEmpty()
	}

	SetSyncStatus(){
		this.Synced = true; 
		this.DoneSyncing()
	}

	GotNewAudio(info){
		if(info){
			if(info.AudioPath !== this.AudioClipPath){
				this.AudioClipPath = info.AudioPath
				this.AudioIsLoaded = false
				this.refs.HowlComp.LoadClip("https://"+this.AudioClipPath, ()=>{
					this.AudioIsLoaded = true
					this.DoneSyncing()
				})
				/*
				this.refs.PhonoComp.LoadClip("https://"+this.AudioClipPath, ()=>{
					this.AudioIsLoaded = true
					this.DoneSyncing()
				})
				*/
			}
		}
	}

	ConnectionIssue(){
		this.props.ConnectionIssueCB()
	}
	
	DoneSyncing(){
		if(this.AudioIsLoaded && this.Synced){
			console.log('done syncing')
			this.setState({synced: true})
			this.props.SyncdCallback()
			WaitForStart(this.ShowStarted.bind(this), this.ShowEnded.bind(this), this.GotNewAudio.bind(this))
		}
	}

	ShowStarted(ShowStartedAt){
		this.props.ShowStartedCB()
		this.setState({started: true})
		//Start Calculating Preroll periodically.
		this.RecalcInterval = setInterval( function(){
			var preroll = CalcPreRoll()

			if(this.refs.HowlComp){
				if(this.refs.HowlComp.Started){
					//Bump the Audio Track.
					this.refs.HowlComp.Bump(preroll)
				} else {
					//Start the Audio Track.
					this.refs.HowlComp.Start(preroll)
				}
			} else {
				clearInterval(this.RecalcInterval)
			}
			/*
			if(this.refs.PhonoComp){
				if(this.refs.PhonoComp.Started){
					//Bump the Audio Track.
					this.refs.PhonoComp.Bump(preroll)
				} else {
					//Start the Audio Track.
					this.refs.PhonoComp.Start(preroll)
				}
			} else {
				clearInterval(this.RecalcInterval)
			}
			*/
		}.bind(this), 100)
	}

	ShowEnded(){
		console.log("Show ended at Sync Audio Interface")
		clearInterval(this.RecalcInterval)
		this.setState({ended: true, started: false})
		this.refs.HowlComp.End()
		//this.refs.PhonoComp.End()
		WaitForStart(this.ShowStarted.bind(this), this.ShowEnded.bind(this))
	}

    render(){
        return (
            <div className="SyncAudioInterface">
				<PhonoPlayer ref="PhonoComp"></PhonoPlayer>
				<HowlPlayer ref="HowlComp"></HowlPlayer>
            </div>
        )
    }
}
SyncAudioInterface.contextType = SyncContext
export default SyncAudioInterface