import React from 'react';

import { Menu } from 'semantic-ui-react'


import logo from "../../../resources/logo.svg"

class AppLayout extends React.Component{
	constructor(props){
		super(props)
		this.state = {}
	}

	render(){
		return (
			<>
				<div className="contentWrapper">
					<Menu inverted color="black">
						<Menu.Item>
							<img src={logo} alt="logo"></img>
							<span className="Name">
								SPARK SYNC
							</span>
						</Menu.Item>
					</Menu>
					<main>
						{<this.props.children/>}
					</main>
					<div className="push"></div>
				</div>
				<footer>
					<p>Copyright (C) <a href="https://www.tactman.com">Tactical Manoeuvre Inc.</a> 2020</p>
				</footer>
			</>
		)
	}
}


export default AppLayout