import React from 'react';

import AppLayout from '../layout/app/app'
import ExternalLayout from '../layout/external/external'

export const ViewContext = React.createContext();

class View extends React.Component{
	constructor(props){
		super(props);

		this.layouts = {
			app: AppLayout,
			external: ExternalLayout
		}


		this.state = {
			loading: false
		}

		this.handleError = this.handleError.bind(this);
		this.startLoading = this.startLoading.bind(this);
		this.finishLoading = this.finishLoading.bind(this);
	}

	startLoading(){
		this.setState({ loading: true });
	}
	
	finishLoading(){
		this.setState({ loading: false });
	}


	handleError(err){
		var message = "There was a glitch in the matrix - Please try again"
		
		if(err){
			message = err.toString();
			if(err.response){
				if(err.response.data){
					if(err.response.data.message){
						message = err.response.data.message
					}
				}
				if(err.response.status){
					switch(err.response.status){
						case 401:
							//go to signin
							this.props.goToSignin()
							break;

						default:
							//nothing
							break;
					}
				}	
			}
		}
		alert(message)
	}



	render(){
		var Layout = AppLayout;
		document.title = this.props.title;

		if (this.props.layout){
			Layout = this.layouts[this.props.layout];
		}
		
		const viewContext = {
			viewProps: this.props,
			handleError: this.handleError,
			startLoading: this.startLoading,
			finishLoading: this.finishLoading
		}

		return(
			<ViewContext.Provider value={ viewContext }>
				{ this.state.loading ?
					<h1>Loading...</h1> :
					<Layout
						title={ this.props.title }
						loading={ this.state.loading }>
						{ this.props.display }
					</Layout>
				}

			</ViewContext.Provider>
		)
	}
}

export default View