import React from 'react'

import {StartSync, WaitForStart, CalcPreRoll} from './sync'

export const SyncContext = React.createContext();

export class SyncProvider extends React.Component {

	constructor(props){
		super(props);
		this.state= {
			syncd: false,
			syncing: false,
			started: false,
			ended: false,
			connectionIssue: false,
			preroll: 0
		}
		this.Route = null
		this.SyncStarted = false
		this.Waiting = false

		this.completedCBs = []
		this.gotInfoCBs = []
		this.connectionIssueCBs = []

		this.showStartedCBs = []
		this.showEndedCBs = []

		this.updateCBs = []
		this.newDataCBs = []
		this.preroll = 0
		this.lastUpdateTime = 0
		this.updateCycleError = 10

		this.SyncOk = this.SyncOk.bind(this)
		this.WaitForStart = this.WaitForStart.bind(this)
		this.Attach = this.Attach.bind(this)
	}

	/**Starts Sync process.
	 * 
	 * @param {String} Route URL for Sync-Core.
	 * @param {function} completedCB Callback to be fired when sync is completed.
	 * @param {function} gotInfoCB	Info has come from Sync-Core.
	 * @param {function} connectionIssueCB There was a connection issue accessing the Sync-Core.
	 */
	SyncOk(Route, completedCB, gotInfoCB, connectionIssueCB){ 
		if(!this.SyncStarted){
			this.SyncStarted = true
			this.Route = Route
			if(this.Route){
				StartSync(this.Route, this.GotShowData.bind(this), this.DoneSyncing.bind(this), this.ConnectionIssue.bind(this))
				this.setState({syncing: true})
			} else {
				this.connectionIssueCB()
			}
		}
		this.completedCBs.push(completedCB)
		this.gotInfoCBs.push(gotInfoCB)
		this.connectionIssueCBs.push(connectionIssueCB)
	}

	ConnectionIssue(){
		this.setState({connectionIssue: true})
		this.connectionIssueCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb()
				} catch(error) {
					console.log(error)
				}
				
			}
		})
	}

	/**Waits for Sync-Core to send messages to trigger callbacks
	 * 
	 * @param {function} startCB callback for when the show starts
	 * @param {function} endedCB callback for when the show ends
	 * @param {function} updateCB callback for preroll updates
	 * @param {function} newDataCB callback for receiving new date from sync-core.
	 */
	WaitForStart(startCB, endedCB, updateCB, newDataCB){
		this.showStartedCBs.push(startCB)
		this.showEndedCBs.push(endedCB)
		this.updateCBs.push(updateCB)
		this.newDataCBs.push(newDataCB)

		if(!this.Waiting){
			WaitForStart(this.ShowStarted.bind(this), this.ShowEnded.bind(this), this.GotNewShowData.bind(this))
			this.Waiting = true
		}
	}

	GotShowData(info){
		this.setState({ShowData: info})
		this.gotInfoCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb(info)
				} catch(error) {
					console.log(error)
				}
				
			}
		})
	}

	GotNewShowData(info){
		this.newDataCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb(info)
				} catch(error) {
					console.log(error)
				}
				
			}
		})
	}
	
	DoneSyncing(){
		this.setState({syncd: true})
		this.gotNewInfoCBs = []
		this.completedCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb()
				} catch(error) {
					console.log(error)
				}
				
			}
		})
	}

	ShowStarted(){
		this.setState({started: true})

		this.showStartedCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb(CalcPreRoll())
				} catch(error) {
					console.log(error)
				}
				
			}
		})

		//Start Calculating Preroll periodically.
		this.RecalcInterval = setInterval( function(){ this.UpdatePreroll() }.bind(this), 10)
	}

	ShowEnded(){
		this.setState({ended: true, started: false})
		
		this.showEndedCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb()
				} catch(error) {
					console.log(error)
				}
				
			}
		})

		clearInterval(this.RecalcInterval)
	}

	UpdatePreroll(){
		this.preroll = CalcPreRoll()
		this.setState({preroll: this.preroll})
		this.updateCBs.forEach(cb=>{
			if(typeof(cb) === 'function'){
				try{
					cb(this.preroll)
				} catch(error) {
					console.log(error)
				}
				
			}
		})
	}

	/**Passive alternative to WaitForStart
	 * 
	 * @param {function} startedCB callback for start of show.
	 * @param {function} endedCB callback for end of show
	 * @param {function} updateCB callback for preroll updates
	 * @param {function} goInfoCB callback for first bits of data the sync-core sends.
	 * @param {function} newDataCB callback for any info the sync-core sends after start.
	 */
	Attach(startedCB, endedCB, updateCB, goInfoCB, newDataCB){
		this.showStartedCBs.push(startedCB)
		this.showEndedCBs.push(endedCB)
		this.updateCBs.push(updateCB)
		this.gotInfoCBs.push(goInfoCB)
		this.newDataCBs.push(newDataCB)
	}

	render(){
		return (
		<SyncContext.Provider value={{
			syncing: this.state.syncing,
			syncd: this.state.syncd,
			connectionIssue: this.state.connectionIssue,
			started: this.state.started,
			ended: this.state.ended,
			showData: this.state.ShowData,
			SyncOk: this.SyncOk,
			WaitForStart: this.WaitForStart,
			Attach: this.Attach,
			preroll: this.preroll}}
			{...this.props} />
    );
};
}