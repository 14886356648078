import React from 'react';
import {isMobile, isSafari, isAndroid } from 'react-device-detect';

import {SyncContext} from '../syncComponent/syncComponent'

const {Howl, Howler} = require('howler');
Howler.autoUnlock = true
Howler.usingWebAudio = true


class SyncAudio extends React.Component{
	constructor(props){
		super(props)
		this.clip = new Howl({
			src: ['https://d3ffar08ccyx1n.cloudfront.net/silence.mp3'],
			autoplay: false,
			loop: false, 
			preload: true
		});
		this.ConstantOffset = 0.0
		if(!isMobile){
			this.ConstantOffset = 0
		}
		if(isSafari){
			this.ConstantOffset = -.1
		}
		if(isAndroid){
			this.ConstantOffset = 0
		}
	}
	componentDidMount(){
		this.props.onRef(this)
		if(this.props.Passive){
			this.context.Attach(this.started.bind(this), this.ended.bind(this), this.update.bind(this), this.gotNewInfo.bind(this))
		}
	}
	
	componentWillUnmount() {
		//Destroy reference on unload.
		this.props.onRef(undefined)
	}
	
	doSync(){
		this.context.SyncOk(this.props.SyncRoute, this.syncProcessCompleted.bind(this), this.gotNewInfo.bind(this), this.connectionIssue.bind(this))
	}

	syncProcessCompleted(){
		this.context.WaitForStart(this.started.bind(this), this.ended.bind(this), this.update.bind(this), this.gotNewInfo.bind(this))
	}

	started(preroll){
		this.Start(preroll)
	}

	ended(){
		this.End()
	}

	update(preroll){
		this.Bump(preroll)
		if(this.clip){
			if(this.clip.playing){
				//Bump the Audio Track.
				this.Bump(preroll)
			} else {
				//Start the Audio Track.
				this.Start(preroll)
			}
		}
	}

	gotNewInfo(message){
		if(message.MessageType === "ping"){
			console.log(message)
		}
		if(message.MessageType === "ShowData"){
			if(message.Data.AudioPath){
				this.LoadClip(message.Data.AudioPath)
				console.log('Loading clip')
			}
		}
	}

	playEmpty(){
		this.clip.play()
	}

	connectionIssue(){
		console.log('Connection issue')
	}

	LoadClip(clipPath){
		this.clip = new Howl({
			src: [clipPath],
			autoplay: false,
			loop: false, 
			preload: true
		});

		this.clip.once('load', function(){
			this.Loaded = true
		})

		this.clip.once('loaderror', function(err){
			window.alert("failed to load clip: "+err)
		})

		this.clip.once('end', function(){
			this.End()
		}.bind(this))

		this.Loaded = false
	}

	Start(preroll){
		this.clip.seek(preroll)
		this.clip.play();
		this.Started = true
	}

	End(){
		this.clip.stop()
		this.clip.unload()
		this.Started = false
		this.Ended = true
		this.Loaded = false
	}

	Bump(preroll){
			var diff = (this.clip.seek() - preroll)-this.ConstantOffset
			var absDiff = Math.abs(diff)
			if(absDiff > 0.05){
				this.SyncdFrames = 0
				//If its in front of the correct timing...
				if(diff > 0){
					//nudge it back.)
					this.playheadOffset -= 0.01
				}
				//If its behind the correct timing...
				if(diff < 0) {
					//nudge it forward.
					this.playheadOffset += 0.01
				}
				this.clip.seek(preroll+this.playheadOffset)
	
	
			} else {
				this.SyncdFrames += 1
			}
		
	}

	render(){
		return(
		<div className="AudioPlayer">
		</div>
		)
	}
}

SyncAudio.contextType = SyncContext

export default SyncAudio