import React from 'react'
import { SyncContext } from '../syncComponent/syncComponent'

import SimonBoard from '../simonBoard/simonBoard'

class SimonInterface extends React.Component{
	constructor(props){
		super(props)

		this.state = {
			Watching: true,
		}
		this.Score = 0
		this.ShowData = null
		this.timings = []
		this.FirstUpdate = true
		this.UpToDate = false
	}

	componentDidMount(){
		//Passively attach to sync lib.
		this.gotNewInfo(this.context.showData)
		this.context.Attach(this.started.bind(this), this.ended.bind(this), this.update.bind(this), this.gotNewInfo.bind(this))
	}

	started(){
		//Sync Show has started.
		console.log('Simon interface: show started')
	}

	ended(){
		//Sync show has ended.
	}

	update(preroll){
		//Calledback every 10ms with an updated preroll time
		//calculated from the sync lib.

		//Make sure we have data and are all loaded up.
		if(this.ShowData){
			//make sure timingObjects exist. 
			if(this.timings.length > 0){

				//Calculate our "frame" from the preroll.
				var frame = Math.floor( (preroll*1000)/10 )*10

				//Grab the next timingObject to compare.
				var nextFrame = this.timings.pop()
				//! TODO Check we aren't cycling all the previous frame on late join
				if(this.FirstUpdate){
					this.FirstUpdate = false
					while(nextFrame.time <= frame || !nextFrame.Watching){
						nextFrame = this.timings.pop()
						console.log(nextFrame)
						if(nextFrame.round){
							this.SimonBoard.setRound(nextFrame.round+1)
						}
					}
					this.timings.push(nextFrame)
					this.UpToDate = true
					return
				}
				if(this.UpToDate){
					//If the frame lines up with the timingObject...
					if(nextFrame.time <= frame){					
						if(nextFrame){ // ? not sure why this is here. 
							//if we are supposed to flash something in this frame...
							if(nextFrame.Flash){
								this.SimonBoard.playSound(nextFrame.Flash)
							}
							//if we are supposed to be watching in this frame...
							if(nextFrame.Watching){
								this.setState({Watching: true})
							}
							//if we are supposed to be playing in this frame...
							if(nextFrame.Playing){
								this.setState({Watching: false})
							}
							//if there is a message in this frame...
							if(nextFrame.Message){
								this.props.UpdateMessage(nextFrame.Message)
							}
						}
					} else {
						//this timeObject is not ready to be triggered yet.
						this.timings.push(nextFrame)
					}
				}
			}
		}
	}

	updateScore(score){
		this.Score +=score 
		this.props.UpdateScore(this.Score)
	}

	gotNewInfo(message){
		//This gets calledback with messages directly from the sync-core. 
		if(message.MessageType === "ping"){
			//These messages will contain number of active players. 
		}
		if(message.MessageType === "ShowData"){
			//This message type will contain a uri to the sidecar file.
			window.fetch(message.Data.SidecarPath)
			.then((res)=>{
				return res.json()})
			.then((data)=>{
				this.timings = data.timings.reverse()
				this.SimonBoard.setSequence(data.sequence)
				this.ShowData = data
			}).catch((msg)=>{
				this.connectionIssue()
			})
		}
	}

	connectionIssue(){
		// This should call back to the view. 
	}

	render(){
		var isHidden = this.props.hidden? "none": "block"
		return(
			<div style={{display: isHidden}}>
				<SimonBoard UpdateScore={this.updateScore.bind(this)} Watching={this.state.Watching} onRef={ref=>(this.SimonBoard = ref)}/>
			</div>
		)
	}
}

SimonInterface.contextType = SyncContext
export default SimonInterface