import React from 'react';

import {Grid} from 'semantic-ui-react'

import './simon.css'

import button1 from '../../resources/simon1.png'
import button2 from '../../resources/simon2.png'
import button3 from '../../resources/simon3.png'
import button4 from '../../resources/simon4.png'

//Audio package.
const {Howl, Howler} = require('howler');
Howler.autoUnlock = true
Howler.usingWebAudio = true


class ImageStackItem extends React.Component{
	render(){
		var classNames = 'simonImage '
		if(this.props.active){
			classNames += ' Active'
		} 
		return(
				<img alt="simon button" className={classNames} src={this.props.Path}></img>
		)
	}
}

class SimonImageButton extends React.Component{
	render(){
		var classNames = 'simonImageButton '+this.props.Color
		if(this.props.active){
			classNames += ' Active'
		} 
		return(
			<div>
				<div onClick={this.props.OnButton} data={this.props.Color} className={classNames}  style={{height: this.props.ButtonHeight, top: this.props.ButtonY}}></div>
			</div>
		)
	}
}

class SimonBoard extends React.Component{
	constructor(props){
		super(props)
		
		this.state = {
			activeButton: null
		}
		this.tonePaths = ["https://d3ffar08ccyx1n.cloudfront.net/tones/tones-01.mp3", "https://d3ffar08ccyx1n.cloudfront.net/tones/tones-02.mp3", "https://d3ffar08ccyx1n.cloudfront.net/tones/tones-03.mp3", "https://d3ffar08ccyx1n.cloudfront.net/tones/tones-04.mp3", "https://d3ffar08ccyx1n.cloudfront.net/tones/tones-05.mp3"]
		this.tones = []
		this.currentTone = null
		this.buttonLookup = {
			'Red':{idx: 1}, 
			'Green':{idx: 2},  
			'Blue':{idx: 3}, 
			'Yellow':{idx: 4}
		}
		this.sequence = [1,2,3]
		this.subSequence = []
		this.userSequence = []
		this.lostRound = false
		this.Watching = true
		this.round = 1
		this.score = 0
	}
//-------------------------------------Component hooks.
	componentDidMount() {
		//Setup reference for simon manager.
		this.props.onRef(this)

		//Load the noises for the buttons.
		this.loadNoises()
	}

	componentWillUnmount() {
		//Destroy reference on unload.
		this.props.onRef(undefined)
	}

	componentDidUpdate(){
		//This happens a lot. We are very interested when this happens
		//due to the Watching prop updating.
		if(this.props.Watching !== this.Watching){
			this.Watching = this.props.Watching
			if(this.Watching){
				//Signals end of the round. Set up for the next round. 
				this.round += 1
				//subSequence is used for tracking how well the player 
				//is matching the sequence
				this.subSequence = this.sequence.slice(0, this.round).reverse()
				this.endRound(this.round)
			}
		}
	}
//--------------------------------------Game setup
	setSequence(seq){
		//Full sequence is loaded before the game. 
		this.sequence = seq
		//Grab the subsequence for the first round. 
		this.subSequence = this.sequence.slice(0, this.round)
	}

	loadNoises(){
		//Load up some noises. 
		this.tonePaths.forEach((path)=>{
			var newTone = new Howl({
				src: [path],
				autoplay: false,
				loop: false, 
				preload: true,
				onplayerror: function(){
					console.log("audio prevented")
					
				}
			});
			this.tones.push(newTone)
		})
	}

	stopAllTones(){
		//Everyone be quiet.
		this.tones.forEach((tone)=>{
			tone.stop()
		})
	}

//-------------------------------------UI
	buttonPressed(e){

		//Check to see if button presses are allowed. 
		if(!this.props.Watching){
			
			//Lookup the button
			var buttonName = e.target.getAttribute('data')
			var buttonID = this.buttonLookup[buttonName].idx
			
			//Flash it - this will also trigger sound.
			this.flashButton(buttonID)
			this.playSound(buttonID)
			//check if its right
			this.userSequence.push(buttonID)
			if(!this.checkSequence(buttonID)){
				this.lost()
			} else {
				this.score += 1
			}
		}
	}

	flashButton(btn){
		btn = btn - 1 //Off by ones - this needs a clean up.
		
		//This will flash the button via css class name.
		this.setState({activeButton: btn})

		//Seek into the tone a bit.
		setTimeout(()=>{ this.buttonFlashOff(btn) }, 100)
	}

	playSound(btn){
		btn = btn - 1 //Off by ones - this needs a clean up.
		//Stop anything that was plying.
		this.stopAllTones()
		this.tones[btn].seek(0.0) //We can rate adjust here in the future. 
		this.tones[btn].volume(1)
		this.currentTone = this.tones[btn].play()
		this.tones[btn].fade(1, 0, 480, this.currentTone)
	}

	buttonFlashOff(btn){
		this.setState({activeButton: null})
	}


//------------------------------------Game mechs

	lost(){
		//What happens when someone loses a round. 
		
		//bool for not losing twice
		this.lostRound = true

		//Stop playing audio so we can get ready for the buzzer
		this.stopAllTones()

		//Seek to beginning. This isn't neccesary - but useful to limit buzzer in the future.
		this.tones[4].seek(0)
		this.currentTone = this.tones[4].play()
	}

	won(){
		//Congrats, I guess.
	}


	checkSequence(id){
		//Check the button we just pressed is next in the subSequence. 
		return (id === this.subSequence.pop())
	}

	endRound(){
		//Check to make sure the user actually played.
		if(this.userSequence.length > 0){
			this.won()
		} else {
			//If a user gets here - they gave up or got distracted. 
			//We might consider timing them out.
			this.lost()
		}
		//Update the score in parent component. 
		this.props.UpdateScore(this.score)
		
		//Reset for the next round.
		this.score = 0
		this.userSequence = []
		this.lostRound = false
	}

	setRound(num){
		this.round = num 
	}


	render(){
		return(
			<div className="SimonBoard">
				<Grid columns='equal'>
					<Grid.Row>
						<Grid.Column>
						<div className="simonImageBoard">
						<ImageStackItem Path={button1}></ImageStackItem>
						<ImageStackItem Path={button2}></ImageStackItem>
						<ImageStackItem Path={button3}></ImageStackItem>
						<ImageStackItem Path={button4}></ImageStackItem>
						<ImageStackItem active={this.state.activeButton === 0} Path={button1}></ImageStackItem>
						<ImageStackItem active={this.state.activeButton === 1} Path={button2}></ImageStackItem>
						<ImageStackItem active={this.state.activeButton === 2} Path={button3}></ImageStackItem>
						<ImageStackItem active={this.state.activeButton === 3} Path={button4}></ImageStackItem>
						<SimonImageButton  active={this.state.activeButton === 3} OnButton={this.buttonPressed.bind(this)} Color="Yellow" ButtonY="80%" ButtonHeight='20%' Name="floor71"/>
						<SimonImageButton  active={this.state.activeButton === 2} OnButton={this.buttonPressed.bind(this)} Color="Blue" ButtonY="62%" ButtonHeight='18%' Name="floor82"/>
						<SimonImageButton  active={this.state.activeButton === 1} OnButton={this.buttonPressed.bind(this)} Color="Green" ButtonY="35%" ButtonHeight='27%' Name="mast"/>
						<SimonImageButton  active={this.state.activeButton === 0} OnButton={this.buttonPressed.bind(this)} Color="Red" ButtonY="0%" ButtonHeight='35%' Name="antenna"/>
						</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		)
	}
}

export default SimonBoard

/*

<SimonButton active={this.state.activeButton === 3} Color="Yellow" OnButton={this.buttonPressed.bind(this)}/>
						<SimonButton active={this.state.activeButton === 2} Color="Blue" OnButton={this.buttonPressed.bind(this)}/>
						<SimonButton active={this.state.activeButton === 1} Color="Green" OnButton={this.buttonPressed.bind(this)}/>
						<SimonButton active={this.state.activeButton === 0} Color="Red" OnButton={this.buttonPressed.bind(this)}/>
*/