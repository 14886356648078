import React from 'react';

import {SyncContext} from '../syncComponent/syncComponent'

import {Image, Grid} from 'semantic-ui-react'

class SimonPreviz extends React.Component{

	constructor(props){
		super(props)
		this.state = {SimonIndex: null}
		this.timings = []
		this.ShowData = null
	}

	componentDidMount(){
		if(this.props.Passive){
			this.context.Attach(this.started.bind(this), this.ended.bind(this), this.update.bind(this), this.gotNewInfo.bind(this))
		}
	}
	
	doSync(){
		this.context.SyncOk(this.props.SyncRoute, this.syncProcessCompleted.bind(this), this.gotNewInfo.bind(this), this.connectionIssue.bind(this))
	}

	syncProcessCompleted(){
		console.log(this.props.Name+' Sync Completed')
		this.context.WaitForStart(this.started.bind(this), this.ended.bind(this), this.update.bind(this), this.gotNewInfo.bind(this))
	}

	started(){
	}

	ended(){
	}

	update(preroll){
		//Calledback every 10ms with an updated preroll time
		//calculated from the sync lib.

		//Make sure we have data and are all loaded up.
		if(this.ShowData){
			//make sure timingObjects exist. 
			if(this.timings.length > 0){

				//Calculate our "frame" from the preroll.
				var frame = Math.floor( (preroll*1000)/10 )*10
				
				//Grab the next timingObject to compare.
				var nextFrame = this.timings.pop()
				
				//If the frame lines up with the timingObject...
				if(nextFrame.time <= frame){					
					if(nextFrame){ // ? not sure why this is here. 
						//if we are supposed to flash something in this frame...
						if(nextFrame.Flash){
							this.setState({SimonIndex: nextFrame.Flash})
							setTimeout(()=>{
								this.setState({SimonIndex: null})
							}, 300)
						}
						//if we are supposed to be watching in this frame...
						if(nextFrame.Watching){
							this.setState({Watching: true})
						}
						//if we are supposed to be playing in this frame...
						if(nextFrame.Playing){
							this.setState({ SimonIndex: null, Watching: false})
						}
					}
				} else {
					//this timeObject is not ready to be triggered yet.
					this.timings.push(nextFrame)
				}
			}
		}
	}

	gotNewInfo(message){
		//This gets calledback with messages directly from the sync-core. 
		if(message.MessageType === "ping"){
			//These messages will contain number of active players. 
			console.log(message)
		}
		if(message.MessageType === "ShowData"){
			console.log(message.Data.SidecarPath)
			//This message type will contain a uri to the sidecar file.
			window.fetch(message.Data.SidecarPath)
			.then((res)=>{
				return res.json()})
			.then((data)=>{
				this.timings = data.timings.reverse()
				this.ShowData = data
			}).catch((msg)=>{
				this.connectionIssue()
			})
		}
	}

	connectionIssue(){
		console.log('Connection issue')
	}

	render(){
		return(
		<div>
			<Grid centered columns={2}>
					<Grid.Column>
					{this.props.Passive ?(<div></div>):(<button onClick={this.doSync.bind(this)}>Sync Ok</button>)}
				<Image hidden={this.state.SimonIndex !== null} src='https://d3ffar08ccyx1n.cloudfront.net/previz/0.jpg'></Image>
				<Image hidden={this.state.SimonIndex !== 1} src='https://d3ffar08ccyx1n.cloudfront.net/previz/1.jpg'></Image>
				<Image hidden={this.state.SimonIndex !== 2} src='https://d3ffar08ccyx1n.cloudfront.net/previz/2.jpg'></Image>
				<Image hidden={this.state.SimonIndex !== 3} src='https://d3ffar08ccyx1n.cloudfront.net/previz/3.jpg'></Image>
				<Image hidden={this.state.SimonIndex !== 4} src='https://d3ffar08ccyx1n.cloudfront.net/previz/4.jpg'></Image>
					</Grid.Column>
			</Grid>
		</div>
		)
	}
}

SimonPreviz.contextType = SyncContext

export default SimonPreviz