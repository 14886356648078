/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */
import React from "react"

// import {isEdge, isIE } from 'react-device-detect'; 

import SyncStarter from '../../components/syncStarter/syncStarter'
import { Segment, Container, Grid, Placeholder} from 'semantic-ui-react'

import SimonPreviz from '../../components/simonPreviz/simonPreviz'

import './simon.css'
import { ViewContext } from "../../components/view/view";

var SyncRouterURL = "https://caesium-worker.tactman.workers.dev/systems/"

class Previz extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			loading: true,
			activated: false,
			connected: true,
			synced: false,
			started: false,
			venueImage: null,
			showOver: false,
			venueLinks: []
		}
		this.SyncRoute = null
		this.SyncTag = null
	}

	componentDidMount(){
		this.SyncTag = this.context.viewProps.match.params.syncTag

		//Ask Sync Router where the sync core is.
		window.fetch(SyncRouterURL+this.SyncTag)
		.then((res)=>{
			//Convert response to JSON
			return res.json()})
		.then((doc)=>{
			//If we got a response...
			if (doc.success){
				this.SyncRoute = doc.publicInfo.link
				this.setState({venueImage: doc.publicInfo.logoPath, venueLinks: doc.publicInfo.links})
			}
		})

	}

	Links(links){
		var toDisplay = []
		if(links){
			links.forEach((linkObject, idx)=> {
				toDisplay.push(<a key={idx} target="_blank" rel="noopener noreferrer" href={linkObject.link}>{linkObject.text}</a>)
			});
		} else {
			toDisplay = <div></div>
		}
		return toDisplay
	}


	handleClick(){
		this.setState({activated:true})
		this.simonBoard.Join(this.SyncRoute)
	}

	handleSyncd(){
		this.setState({synced:true})
	}

	handleShowStarted(){
		this.setState({started:true})
	}

	handleNoCore(){
		this.setState({connected: false})
	}

	handleShowOver(){
		this.setState({showOver: true})
	}

	doFlash(){
		this.simonBoard.flashButton(0)
	}

	render(){

		return(
			<div>
			<Container>
			<Grid centered>
					<Grid.Row>
						<Grid.Column mobile={8} computer={8}>
							{this.state.venueImage ? (
								<div style={{backgroundColor: '#fff', width:"100%", padding: '5%'}}><img className="ProjectImage" src={this.state.venueImage} alt="venue logo"></img></div>
							):(
								<Placeholder>
									<Placeholder.Image></Placeholder.Image>
								</Placeholder>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
					<Segment inverted>
						<SyncStarter SyncRoute={this.SyncRoute} Label="Start the Previsualization"></SyncStarter>
					<SimonPreviz Passive />
					</Segment>
			</Container>
			</div>
		)
	}
}


Previz.contextType = ViewContext
export default Previz;