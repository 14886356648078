/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */
import React from "react"

// import {isEdge, isIE } from 'react-device-detect'; 

import SyncStarter from '../../components/syncStarter/syncStarter'
import { Segment, Container, Grid, Transition, Placeholder} from 'semantic-ui-react'

import SimonInterface from '../../components/simonBoard/simonManager'

import './simon.css'
import { ViewContext } from "../../components/view/view";

var SyncRouterURL = "https://caesium-worker.tactman.workers.dev/systems/"


const Rules = (shown) =>{
	return (
		<div>
		{shown? (
				<div>
					<h2>How to Play:</h2>
					It’s the classic game of Simon that we all know and love, but on an iconic scale!
					<br/>
					<br/>
					Watch as the Empire State Building flashes in a series of colors and tones. Tap the corresponding colors on your device to repeat the pattern that you just saw in the correct order. The longer you play, the more complex the sequences become. One wrong tap and you’re out for that round!
					<br/>
					<br/>
					Players accrue points each round based on the accuracy and speed of their response. Try to make it to the top of the leaderboard! Do you have what it takes to become a Simon Slayer?
				</div>
		):(
			<div></div>
		)}
		</div>
	)
}



class Simon extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			loading: true,
			activated: false,
			connected: true,
			synced: false,
			started: false,
			venueImage: null,
			showOver: false,
			message: "The game will begin shortly",
			venueLinks: [],
			score: 0,
			numPlayers: 0,
			showSimon: false
		}
		this.SyncRoute = null
		this.SyncTag = null
	}

	componentDidMount(){
		this.SyncTag = this.context.viewProps.match.params.syncTag

		//Ask Sync Router where the sync core is.
		window.fetch(SyncRouterURL+this.SyncTag)
		.then((res)=>{
			//Convert response to JSON
			return res.json()})
		.then((doc)=>{
			//If we got a response...
			if (doc.success){
				this.SyncRoute = doc.publicInfo.link
				this.setState({venueImage: doc.publicInfo.logoPath, venueLinks: doc.publicInfo.links})
			}
		})

	}

	Links(links){
		var toDisplay = []
		if(links){
			links.forEach((linkObject, idx)=> {
				toDisplay.push(<a key={idx} target="_blank" rel="noopener noreferrer" href={linkObject.link}>{linkObject.text}</a>)
			});
		} else {
			toDisplay = <div></div>
		}
		return toDisplay
	}

	handleStartedSyncing(){
		this.setState({activated: true})
	}

	handleSyncd(){
		this.setState({synced:true, showSimon: true})
	}

	handleConnectionIssue(){
		this.setState({connected: false})
		console.log('connection issue')
	}

	handleShowOver(){
		this.setState({
			showOver: true, 
			showSimon: false,
			message: "Game Over!"})
	}

	handleUpdateScore(score){
		this.setState({score: score})
	}

	handleUpdateMessage(message){
		this.setState({message: message})
	}
	handleUserUpdate(num){
		this.setState({numPlayers: num})
	}

	render(){

		return(
			<div>
			<Container>
				<Grid centered>
					<Grid.Row>
						<Grid.Column mobile={10} computer={8}>
							{this.state.venueImage ? (
								<div style={{backgroundColor: '#fff', width:"100%", padding: '5%'}}><img className="ProjectImage" src={this.state.venueImage} alt="venue logo"></img></div>
							):(
								<Placeholder>
									<Placeholder.Image></Placeholder.Image>
								</Placeholder>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
					
					<Transition visible={this.state.synced && !this.state.showOver} animation='swing up' duration={500}>
						<div className="infoSection">
							<div className='messageContainer'>
								{this.state.message}
							</div>
							<Grid columns="equal">
								<Grid.Row>
									<Grid.Column>
									<div className="scoreContainer">
										Score:&nbsp;
										<span className="highlight">{this.state.score}</span>
									</div>
									</Grid.Column>
									<Grid.Column>
									<div className="playersContainer">
										Total Players:&nbsp;
										<span className="highlight">{this.state.numPlayers}</span></div>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
					</Transition>

				{/* Game Over Screen */}
				<Transition visible={this.state.showOver} animation='drop' duration={500}>
					<div className="gameOverWrapper">
					<Grid>
						<Grid.Row>
							<Grid.Column textAlign="center">
								<div className='messageContainer'>
									Game Over!
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column textAlign="center">
							<div className="superlativeContainer">
								<span>Score</span>
								<br/>
								<br/>
								<span className="Superlative">
									{this.state.score}pts
								</span>
							</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column textAlign="center">
								<div className="projectTag">
									#SimonLive
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					</div>
				</Transition>						


				{/* Playable Screen*/}
				<Transition visible={this.state.showSimon} animation='drop' duration={500}>
					<Segment className="PlayArea" inverted>
						<SimonInterface 
							hidden={!this.state.showSimon}
							UpdateScore={this.handleUpdateScore.bind(this)}
							UpdateMessage={this.handleUpdateMessage.bind(this)}>
						</SimonInterface>
					</Segment>
				</Transition>


				{/* Lobby Screen */}
				<Transition visible={!this.state.synced} animation='drop' duration={500}>
					<Segment className="LoadingArea" inverted>
						{Rules(!this.state.activated)}
						<br/>
						<SyncStarter 
							hidden={!this.state.synced}
							SyncStarted={this.handleStartedSyncing.bind(this)}
							SyncRoute={this.SyncRoute} 
							SyncdCB={this.handleSyncd.bind(this)} 
							UsersUpdate={this.handleUserUpdate.bind(this)}
							ConnectionIssueCB={this.handleConnectionIssue.bind(this)}
							EndShow={this.handleShowOver.bind(this)}
							Label="Click here to Join">
						</SyncStarter>
					</Segment>
				</Transition>
			</Container>
			</div>
		)
	}
}


Simon.contextType = ViewContext
export default Simon;